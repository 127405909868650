  /**
   * Author: Neeti
   * Project: Ludo Board Game
   * Copyright: Moblize.IT LLC | All rights reserved
   * Last Modified: Apr 19 2020
   * 
   * Description: Poopup component whenever a player reaches target 
   * 
   */
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-eat-pizza',
  templateUrl: './eat-pizza.component.html',
  styleUrls: ['./eat-pizza.component.scss'],
})
export class EatPizzaComponent implements OnInit {
  @Input() name:string
  @Input() img:string

  constructor(public modalController:ModalController) { }

  ngOnInit() {

  }

  close(){
    this.modalController.dismiss()
  }

  ionViewDidEnter(){
    console.log("eat pizza entered")
  }

}
